import React from 'react';
import {EnvelopeIcon} from "@heroicons/react/24/outline";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithub, faLinkedin} from "@fortawesome/free-brands-svg-icons";

function App() {
    return (
        <div className="flex items-center min-h-screen min-w-full bg-neutral-700 text-neutral-50">
            <div className="text-center min-w-full">
                <span className="clear-both">Hi :)</span>

                <div className="mt-4">
                    <a href="https://linkedin.com/in/pavelkuzmenkov" target="_blank" className="text-neutral-400 hover:text-neutral-300">
                        <FontAwesomeIcon icon={faLinkedin} className="w-6 h-6 m-1" />
                    </a>
                    <a href="https://github.com/iaretedd" target="_blank" className="text-neutral-400 hover:text-neutral-300">
                        <FontAwesomeIcon icon={faGithub} className="w-6 h-6 m-1" />
                    </a>
                </div>
            </div>
        </div>
    );
}/*
function App2() {
  return (
    <div className="container grid grid-cols-3">
      <div className="bg-sky-900 text-gray-100 p-5 min-h-screen">
          <div className="mb-8">
              <h3 className="text-gray-300 text-xl mb-4 pb-2 border-b-2 inline-block clear-both font-semibold">Contacts</h3>

              <div className="flex items-center mb-2">
                  <EnvelopeIcon className="w-6 h-6 mr-2" />
                  <span>
                      offers@kuzmenkov.dev
                  </span>
              </div>

              <div className="flex items-center mb-2">
                  <FontAwesomeIcon icon={faLinkedin} className="w-6 h-6 mr-2" />

                  <span>
                      linkedin.com/in/pavelkuzmenkov
                  </span>
              </div>

              <div className="flex items-center mb-2">
                  <FontAwesomeIcon icon={faGithub} className="w-6 h-6 mr-2" />

                  <span>
                      github.com/iaretedd
                  </span>
              </div>
          </div>

          <div className="mb-8">
              <h3 className="text-gray-300 text-xl mb-4 pb-2 border-b-2 inline-block clear-both font-semibold">Skills</h3>

              <div className="mb-4">
                  <div>
                      Backend Software Development
                  </div>

                  <div className="grid grid-cols-5 gap-1">
                      <div className="bg-gray-300 h-2"></div>
                      <div className="bg-gray-300 h-2"></div>
                      <div className="bg-gray-300 h-2"></div>
                      <div className="bg-gray-300 h-2"></div>
                      <div className="bg-gray-300 h-2"></div>
                  </div>
              </div>

              <div className="mb-4">
                  <div>
                      Frontend Software Development
                  </div>

                  <div className="grid grid-cols-5 gap-1">
                      <div className="bg-gray-300 h-2"></div>
                      <div className="bg-gray-300 h-2"></div>
                      <div className="bg-gray-300 h-2"></div>
                      <div className="bg-gray-300 h-2"></div>
                      <div className="bg-sky-700 h-2"></div>
                  </div>
              </div>
          </div>
      </div>
      <div className="bg-gray-50 text-gray-700 p-5 col-span-2 min-h-full">
          Hey
      </div>
    </div>
  );
}*/

export default App;
